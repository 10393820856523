import { IDataAccess } from "./IDataAccess";

// TODO: Do poprawy po ustaleniu roli oraz grupy

export interface IAccountManager {
  id?: number;
  name?: string;
  skype?: string | null;
  telegram?: string | null;
  email?: string;
}

export interface IAppUserInfoModel {
  id?: number;
  name?: string;
  id_country?: number;
  country_name?: string;
  second_name?: string;
  id_company?: number;
  email?: string;
  users_group?: string[];
  role?: string[];
  token?: string;
  permissions?: number[];
  id_account_manager?: number;
  acc?: IDataAccess;
  id_users_role?: number;
  account_manager?: IAccountManager;
}

export enum UserPermissionType {
  DashboardView = 1,
  OffersListView = 2,
  StatisticsView = 3,
  LeadsView = 4,
  EmployeesView = 5,
  ProductsView = 6,
  CampaignsPartnerView = 7,
  PartnersView = 8,
  ToolsView = 9,
  FinanceView = 10,
  NewsView = 11,
  SupportView = 12,
  FaqView = 13,
  VerificationToolView = 13,
  UsersView = 14,
  UsersRoleView = 15,
  RolePermissionsView = 16,
  InstancesView = 17,
  CallCenterCampaignsView = 18,
  ClassifiersVies = 19,
  MappingClassifiersView = 20,
  CompaniesView = 21,
  ProfileView = 23,
  UsersInformationView = 24, //// < -----  TODO: Brak zastosowania
  CreateNewOrderView = 25,
  WebhooksView = 26,
  CountriesDictionaryView = 27,
  CitiesDictionaryView = 28,
  CouriersDictionaryView = 29,
  DistrictsDictionaryView = 30,
  StatusesDictionaryView = 31,
  LocationsDictionaryView = 32,
  PostCodesDictionaryView = 33,
  PaymentMethodsDictionaryView = 34,
  ProductCategoriesDictionaryView = 35,
  SalesChannelsDictionaryView = 36,
  SubdistrictsDictionaryView = 37,
  ProvincesDictionaryView = 38,
  PagesView = 39,
  OffersDetailsView = 40,
  EmployeesTableChangeStatusButton = 41,
  EditEmployeerModalButton = 42,
  AddNewEmployeeModalButton = 43,
  AddNewProductLinkButton = 44,
  EditProductLinkButton = 45,
  AddNewPageModalButton = 46,
  EditPageModalButton = 47,
  DeletePageButton = 48,
  AddNewCompanyModalButton = 49,
  EditCompanieModalButton = 50,
  AddNewPartnerModalButton = 51,
  EditPartnerModalButton = 52,
  GrantPartnerButton = 53,
  AddNewUserAdministrationModalButton = 54,
  EditUserAdministrationModalButton = 55,
  ChangeUserStatusAdministrationButton = 56,
  DeleteUserAdministrationButton = 57,
  AddNewRoleAdministrationModalButton = 58,
  EditRoleAdministrationModalButton = 59,
  ChangeRoleActiveStatusAdministrationButton = 60,
  EditRoleAdministrationLinkButton = 61,
  DeleteRoleAdministrationButton = 62,
  ChangePermissionStatusAdministrationButton = 63,
  AddNewInstanceModalButton = 64,
  ChangeInstanceStatusButton = 65,
  EditClassifierModalButton = 66,
  ChangeClassifierStatusButton = 67,
  AddNewMappingClassifierModalButton = 68,
  SeeLeadStatistics = 69,
  SeeLeadsFiltersModalButton = 70,
  AddNewCountryModalButton = 71,
  EditCountryModalButton = 72,
  ChangeCountryActiveStatusButton = 73,
  DeleteCountryButton = 74,
  AddNewCityModalButton = 75,
  EditCityModalButton = 76,
  DeleteCityButton = 77,
  AddNewCourierModalButton = 78,
  EditCourierModalButton = 79,
  AddNewDistrictModalButton = 80,
  EditDistrictModalButton = 81,
  DeleteDistrictButton = 82,
  AddNewLocationModalButton = 83,
  EditLocationModalButton = 84,
  DeleteLocationButton = 85,
  AddNewStatusModalButton = 86,
  EditStatusModalButton = 87,
  AddNewPostCodeModalButton = 88,
  EditPostCodeModalButton = 89,
  DeletePostCodeButton = 90,
  AddNewPaymentMethodModalButton = 91,
  EditPaymentMethodModalButton = 92,
  ChangePaymentMethodStatusButton = 93,
  DeletePaymentMethodButton = 94,
  AddNewProductCategoryModalButton = 95,
  EditProductCategoryModalButton = 96,
  ChangeProductCategoryStatusButton = 97,
  DeleteProductCategoryButton = 98,
  AddNewSalesChannelModalButton = 99,
  EditSalesChannelModalButton = 100,
  ChangeSalesChanelStatusButton = 101,
  DeleteSalesChannelButton = 102,
  AddNewSubdistrictModalButton = 103,
  EditSubdistrictModalButton = 104,
  DeleteSubdistrictButton = 105,
  AddNewProvinceModalButton = 106,
  EditProvinceModalButton = 107,
  DeleteProvinceButton = 108,
  EditMappingClassifierModalButton = 109,
  FinanceAdminView = 110,
  CallCenterCampaignsDetailsView = 111,
  CampaignsAdminView = 112,
  AddNewCampaignModalButton = 113,
  EditCampaignModalButton = 114,
  ChangeCampaignsStatusButton = 115,
  SeeCampaignsDetailsLinkButton = 116,
  DeleteCampaignButton = 117,
  AddNewPixelButton = 118,
  SavePixelsChangesButton = 119,
  DeletePixelButton = 120,
  SeePixelsList = 121,
  AddNewPageInProductsLandinggsModalButton = 122,
  SeePrelandingsTable = 123,
  SeeLandingsTable = 124,
  EditLandingOrPrelandingModalButton = 125,
  EditDefaultPriceModalButton = 126,
  AddNewProductPricingButton = 127,
  SeeProductPricingContent = 128,
  SaveProductPricingChangesButton = 129,
  DeleteProductPricingButton = 130,
  OrderView = 131,
  LeadsRedirectionView = 132,
  AddNewLeadsRedirectionModalButton = 133,
  ChangeLeadsRedirectionStatusButton = 134,
  EditLeadsRedirectionModalButton = 135,
  OrderDetailsView = 136,
  OrderDetailsLinkButton = 137,
  CourierStatusMapping = 138,
  SystemVariablesDictionaryView = 146,
  AddNewSystemVariablesModalButton = 147,
  DeleteSystemVariableButton = 148,
  ChangeSystemVariableStatusButton = 149,
  EditSystemVariableModalButton = 150,
  CouriersApiMappingDictionaryView = 151,
  AddNewCouriersApiMappingModalButton = 152,
  DeleteCouriersApiMappingButton = 153,
  EditCouriersApiMappingModalButton = 154,
  CouriersStatusesDictionaryView = 155,
  AddNewCouriersStatuseModalButton = 156,
  DeleteCouriersStatusButton = 157,
  EditCouriersStatusModalButton = 158,
  DeleteCourierButton = 159,
  NewsAdminView = 167,
  SystemClassifiersView = 317,
  AddNewSystemClassifierModalButton = 319,
  EditSystemClassifierModalButton = 321,
  SeeLeadsSelectPartnerButton = 344,
  EditEmployeesRoleLinkButton = 346,
  SeeMegaUploadButton = 364,
  SeeHoldValueComponent = 160,
  SeeBalanceValueComponent = 161,
  SeeUserManagerComponent = 162,
  ViewPasswordForm = 163,
  ViewAccountInformationsForm = 164,
  ViewUserInformationsContent = 165,
  SeeCCClassifiersColumn = 166,
  SeeTransactionTypeSelect = 169,
  LeadsSendPostback = 365,
  LeadsSendToCallCenter = 366,
  LeadsCheckStatusInCallCenter = 367,
  WebSocketView = 369,
  SeeButtonAddNews = 168,
  CreateDepositModalButton = 170,
  CreateWithdrowModalButton = 171,
  AcceptTransactionButton = 172,
  RejectTransactionButton = 173,
  EditNewsModalButton = 174,
  DeleteNewsButton = 175,
  DenyPartnerButton = 176,
  AccountManagersView = 177,
  AddNewAccountManagerModalButton = 178,
  EditAccountManagerLinkButton = 179,
  AccountManagersTableChangeStatusButton = 180,
  EditAccountManagerModalButton = 181,
  CountLeadsStatusUnrecognizedComponent = 182,
  CountLeadsTotalComponent = 183,
  CountLeadsStatusSoldComponent = 184,
  CountLeadsAwaitingAllComponent = 185,
  VerifiedApprovrdRateComponent = 186,
  ApprovrdRateComponent = 187,
  CountLeadsStatusValidatedComponent = 188,
  CountLeadsStatusSendToCcComponent = 189,
  CountLeadsStatusAwaitingComponent = 190,
  CountLeadsStatusRejectedComponent = 191,
  CountLeadsStatusExcludedComponent = 192,
  CountLeadsStatusTrashComponent = 193,
  CountLeadsStatusDoubledComponent = 194,
  OrderCreatedComponent = 195,
  CountNoMoneyComponent = 196,
  SeeLeadsByCompanyAndPartnerComponent = 197,
  SeeCCClassifiersEndColumn = 198,
  SeeCommentColumn = 199,
  SalesP2View = 200,
  SalesResaleView = 201,
  SalesWinbackView = 202,
  SeeLeadsSelectRemarketingCampaignButton = 203,
  ImpersonateAction = 370,
  SeeCompanyMembersLinkButton = 204,
  SeeOriginalProductCountry = 371,
  SeeLeadsSelectCompanyButton = 206,
  K8ServicesView = 207,
  SeeContactedStatus = 372,
  SeeQualifiedStatus = 373,
  SettingsView = 373,
  TrafficSourcesDictionaryView = 374,
  AddNewTrafficSourcesModalButton = 375,
  DeleteTrafficSourceButton = 376,
  ChangeTrafficSourceStatusButton = 377,
  EditTrafficSourceModalButton = 378,
  FinanceStatisticsView = 379,
  CountryManagerRole = 238,
}
