import * as React from "react";
import moment from "moment";
import styles from "./styles/FilterIndicator.module.scss";
import { IDictionaryValue } from "../../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { IFilterIndicator } from "./interfaces/IFilterIndicator";
import { resources } from "../../../../common/Resources";
import { timezones } from "../../../../common/dictionaries/Timezones";

export class FilterIndicator extends React.Component<IFilterIndicator> {
  render() {
    // FIXME bit hacky, should use "displayValue" or something similar
    const filtersSchema = {
      dateFrom: {
        type: "date",
        label: resources.labels.dateFrom,
      },
      dateTo: {
        type: "date",
        label: resources.labels.dateTo,
      },
      id: {
        type: "string",
        label: resources.labels.id,
      },
      id_lead_status: {
        type: "select",
        values: this.props.statusesD,
        label: resources.labels.status,
      },
      country_id: {
        type: "select",
        values: this.props.countriesNameD,
        label: resources.labels.country,
      },
      id_product: {
        type: "select",
        values: this.props.productsD,
        label: resources.labels.product,
      },
      id_partner: {
        type: "select",
        values: this.props.partnersD,
        label: resources.labels.partner,
      },
      payout_scheme: {
        type: "select",
        values: this.props.payoutSchemaD,
        label: resources.labels.payoutScheme,
      },
      api_click_id: {
        type: "string",
        label: resources.labels.click_id,
      },
      api_pub_id: {
        type: "string",
        label: resources.labels.pub_id,
      },
      client_phone1: {
        type: "string",
        label: resources.labels.phone,
      },
      cc_classifiers_name: {
        type: "string",
        values: this.props.ccClassifiersD,
        label: resources.labels.classifierName,
      },
      timezone: {
        type: "select",
        values: timezones,
        label: resources.labels.timezone,
      },
      traffic_source: {
        type: "select",
        values: this.props.remarketingCampaignsD,
        label: resources.labels.remarketingCampaign,
      },
      id_company: {
        type: "select",
        values: this.props.companiesD,
        label: resources.labels.company,
      },
      is_contacted: {
        type: "select",
        values: this.props.booleanSelect,
        label: resources.labels.is_contacted,
      },
      is_qualified: {
        type: "select",
        values: this.props.booleanSelect,
        label: resources.labels.is_qualified,
      },
    };

    const { filters } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{resources.labels.selectedFilters}:</div>
        {Object.keys(filters)
          .filter(
            (filterKey: string) =>
              filters[filterKey] !== undefined &&
              filters[filterKey] !== "" &&
              filterKey in filtersSchema
          )
          .map((filterKey: string) => {
            if (!filtersSchema[filterKey]) {
              return undefined;
            } else if (filtersSchema[filterKey].type === "string") {
              return `${filtersSchema[filterKey].label}: ${filters[filterKey]}`;
            } else if (filtersSchema[filterKey].type === "select") {
              const selectedValue = filtersSchema[filterKey].values.find(
                (value: IDictionaryValue) => value.id === filters[filterKey]
              );
              if (selectedValue) {
                return `${filtersSchema[filterKey].label}: ${selectedValue.name}`;
              }
            } else if (filtersSchema[filterKey].type === "date") {
              return `${filtersSchema[filterKey].label}: ${moment(
                filters[filterKey]
              )
                .format("YYYY/MM/DD HH:mm:ss")
                .toString()}`;
            }
            return undefined;
          })
          .map(
            (el: string | undefined) =>
              el && (
                <div className={styles.filter} key={el}>
                  {el}
                </div>
              )
          )}
      </div>
    );
  }
}
