import * as React from "react";
import styles from "./styles/LeadsStatistics.module.scss";
import { Divider } from "antd";
import { hasPermission } from "../../../../authorizations/AuthorizationLogic";
import { ILeadsStatisticsProps } from "./interfaces/ILeadsStatisticsProps";
import { ILoading } from "../../../../common/interfaces/ILoading";
import { resources } from "../../../../common/Resources";
import { Statistic } from "./components/LeadsStatistic/Statistic";
import { UserPermissionType } from "../../../../models/IAppUserInfoModel";

export class LeadsStatisticsComponent extends React.Component<
  ILeadsStatisticsProps & ILoading
> {
  render() {
    const {
      countLeadsStatusUnrecognized,
      countLeadsTotal,
      countLeadsStatusAwaiting,
      countLeadsStatusRejected,
      countLeadsStatusSold,
      countLeadsStatusTrash,
      countLeadsStatusExcluded,
      countLeadsStatusValidated,
      countLeadsStatusSendToCc,
      countLeadsStatusDoubled,
      verifiedApprovrdRate,
      countLeadsAwaitingAll,
      approvedRate,
      orderCreated,
      countNoMoney,
      loading,
    } = this.props;

    const format = "fi-FI";

    return (
      <div>
        <div className={styles.statistics_container}>
          {hasPermission(
            UserPermissionType.CountLeadsStatusUnrecognizedComponent
          ) &&
            countLeadsStatusUnrecognized > 0 && (
              <div
                className={styles.statistic_container}
                //TODO: Filtrowanie po statystykachDO: Filtrowanie po statystykach
                // onClick={() => this.props.handleChageFilter(Status.Unrecognized)}
              >
                <Statistic
                  stat={countLeadsStatusUnrecognized.toLocaleString(format)}
                  name={resources.statistics.unrecognized}
                  hint={resources.statistics.unrecognized + " statuses"}
                  loading={loading}
                  showError={true}
                />
              </div>
            )}
          {hasPermission(UserPermissionType.CountLeadsTotalComponent) && (
            <div className={styles.statistic_container}>
              <Statistic
                stat={countLeadsTotal.toLocaleString(format)}
                name={resources.statistics.total}
                hint={resources.statistics.totalDescription}
                loading={loading}
                showHover={false}
              />
            </div>
          )}
          {hasPermission(UserPermissionType.CountLeadsStatusSoldComponent) && (
            <div
              className={styles.statistic_container}
              //TODO: Filtrowanie po statystykach
              // onClick={() => this.props.handleChageFilter(Status.Sold)}
            >
              <Statistic
                stat={countLeadsStatusSold.toLocaleString(format)}
                name={resources.statistics.sold}
                hint={resources.statistics.soldDescription}
                loading={loading}
              />
            </div>
          )}
          {hasPermission(UserPermissionType.CountLeadsAwaitingAllComponent) && (
            <div className={styles.statistic_container}>
              <Statistic
                stat={countLeadsAwaitingAll.toLocaleString(format)}
                name={resources.statistics.awaitingAll}
                hint={resources.statistics.awaitingAllDescription}
                loading={loading}
                showHover={false}
              />
            </div>
          )}
          {hasPermission(UserPermissionType.VerifiedApprovrdRateComponent) && (
            <div className={styles.statistic_container}>
              <Statistic
                stat={`${(verifiedApprovrdRate
                  ? verifiedApprovrdRate
                  : 0
                ).toFixed(2)}%`}
                name={resources.statistics.verifiedApprovrdRate}
                hint={resources.statistics.verifiedApprovrdRateDescription}
                showStatisticColors={true}
                showHover={false}
                loading={loading}
              />
            </div>
          )}
          {hasPermission(UserPermissionType.ApprovrdRateComponent) && (
            <div className={styles.statistic_container}>
              <Statistic
                stat={`${(approvedRate ? approvedRate : 0).toFixed(2)}%`}
                name={resources.statistics.approvedRate}
                hint={resources.statistics.approvedRateDescription}
                showStatisticColors={true}
                showHover={false}
                loading={loading}
              />
            </div>
          )}
          {hasPermission(
            UserPermissionType.CountLeadsStatusValidatedComponent
          ) && (
            <div
              className={styles.statistic_container}
              //TODO: Filtrowanie po statystykach
              // onClick={() => this.props.handleChageFilter(Status.Validated)}
            >
              <Statistic
                stat={countLeadsStatusValidated.toLocaleString(format)}
                name={resources.statistics.validated}
                hint={resources.statistics.validatedDescription}
                loading={loading}
              />
            </div>
          )}
          {hasPermission(
            UserPermissionType.CountLeadsStatusSendToCcComponent
          ) && (
            <div
              className={styles.statistic_container}
              //TODO: Filtrowanie po statystykach
              // onClick={() => this.props.handleChageFilter(Status.SendToCc)}
            >
              <Statistic
                stat={countLeadsStatusSendToCc.toLocaleString(format)}
                name={resources.statistics.sendToCC}
                hint={resources.statistics.sendToCcDescription}
                loading={loading}
              />
            </div>
          )}
          {hasPermission(
            UserPermissionType.CountLeadsStatusAwaitingComponent
          ) && (
            <div
              className={styles.statistic_container}
              //TODO: Filtrowanie po statystykach
              // onClick={() => this.props.handleChageFilter(Status.Awaiting)}
            >
              <Statistic
                stat={countLeadsStatusAwaiting.toLocaleString(format)}
                name={resources.statistics.awaiting}
                hint={resources.statistics.awaitingDescription}
                loading={loading}
              />
            </div>
          )}
          {hasPermission(
            UserPermissionType.CountLeadsStatusRejectedComponent
          ) && (
            <div
              className={styles.statistic_container}
              //TODO: Filtrowanie po statystykach
              // onClick={() => this.props.handleChageFilter(Status.Rejected)}
            >
              <Statistic
                stat={countLeadsStatusRejected.toLocaleString(format)}
                name={resources.statistics.rejected}
                hint={resources.statistics.rejectedDescription}
                loading={loading}
              />
            </div>
          )}
          {hasPermission(
            UserPermissionType.CountLeadsStatusExcludedComponent
          ) && (
            <div
              className={styles.statistic_container}
              //TODO: Filtrowanie po statystykach
              // onClick={() => this.props.handleChageFilter(Status.Excluded)}
            >
              <Statistic
                stat={countLeadsStatusExcluded.toLocaleString(format)}
                name={resources.statistics.excluded}
                hint={resources.statistics.excludedDescription}
                loading={loading}
              />
            </div>
          )}
          {hasPermission(UserPermissionType.CountLeadsStatusTrashComponent) && (
            <div
              className={styles.statistic_container}
              //TODO: Filtrowanie po statystykach
              // onClick={() => this.props.handleChageFilter(Status.Trash)}
            >
              <Statistic
                stat={countLeadsStatusTrash.toLocaleString(format)}
                name={resources.statistics.trash}
                hint={resources.statistics.trashDescription}
                loading={loading}
              />
            </div>
          )}
          {hasPermission(
            UserPermissionType.CountLeadsStatusDoubledComponent
          ) && (
            <div
              className={styles.statistic_container}
              //TODO: Filtrowanie po statystykach
              // onClick={() => this.props.handleChageFilter(Status.Double)}
            >
              <Statistic
                stat={countLeadsStatusDoubled.toLocaleString(format)}
                name={resources.statistics.double}
                hint={resources.statistics.doubledDescription}
                loading={loading}
              />
            </div>
          )}
        </div>
        <div>
          {(hasPermission(UserPermissionType.OrderCreatedComponent) ||
            hasPermission(UserPermissionType.CountNoMoneyComponent)) && (
            <Divider>{resources.statistics.callCenterTitle}</Divider>
          )}
        </div>
        <div className={styles.statistics_container}>
          {hasPermission(UserPermissionType.OrderCreatedComponent) && (
            <div
              className={styles.statistic_container}
              //TODO: Filtrowanie po statystykach
              // onClick={() => this.props.handleChageFilter(Status.OrderCreated)}
            >
              <Statistic
                stat={orderCreated.toLocaleString(format)}
                name={resources.statistics.orderCreated}
                hint={resources.statistics.orderCreatedDescription}
                loading={loading}
              />
            </div>
          )}
          {hasPermission(UserPermissionType.CountNoMoneyComponent) && (
            <div className={styles.statistic_container}>
              <Statistic
                stat={countNoMoney.toLocaleString(format)}
                name={resources.statistics.noMoney}
                hint={resources.statistics.noMoneyDescription}
                loading={loading}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
