import DictionaryF from "../../../../../components/templateForm/form/dropDown/DictionaryF";
import React, { FC } from "react";
import styles from "../styles/Styles.module.scss";
import TextInputF from "../../../../../components/templateForm/form/textInput/TextInputF";
import { Button, Checkbox, DatePicker, Icon, Select } from "antd";
import { Form, FormikBag, FormikErrors, FormikProps, withFormik } from "formik";
import { IDictionaryValue } from "../../../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { IHideModal } from "../../../../../common/interfaces/IHideModal";
import { openSuccessNotification } from "../../../../../helpers/NotificationHelper";
import { resources } from "../../../../../common/Resources";
import {
  getFutureProxyPayouts,
  updatePayouts,
} from "../../../../../services/payouts/PayoutsServices";
import { timezones } from "../../../../../common/dictionaries/Timezones";
import moment from "moment";
import { useQuery } from "react-query";
import { PayoutsHistory } from "../table/PayoutsHistory";

export interface IPayoutAccessFormProps {
  name?: string;
  id?: string;
  started_at?: string;
  timezone: string;
  countryNameD?: IDictionaryValue[];
  product_sku?: string;
  country_id?: number;
  payout_cpa?: number;
  payout_cpl?: number;
  is_visible?: string | number;
  payout_scheme?: string;
  product_id?: number;
  user_id?: number;
  apply_immediately: boolean;
  guaranteed_approve: number;
  ga_value: number;
}

const payoutSchemeD = [
  { id: "cpa", name: "CPA", value: "cpa" },
  { id: "cpl", name: "CPL", value: "cpl" },
];

const visibilityD = [
  { id: "0", name: "Hidden", value: "0" },
  { id: "1", name: "Visible", value: "1" },
  { id: "2", name: "Default", value: "2" },
];

export const PayoutAccessFormInner: FC<
  IPayoutAccessFormProps & IHideModal & FormikProps<IPayoutAccessFormProps>
> = (props) => {
  const { isLoading, data } = useQuery(
    "payouts-proxy-future",
    () =>
      getFutureProxyPayouts(
        props.user_id as number,
        props.product_id as number
      ),
    {
      onSuccess: (data) => {
        if (!data.id || data.is_handled) return;

        props.setFieldValue("started_at", data.started_at);
        props.setFieldValue("timezone", data.timezone);
        props.setFieldValue("payout_cpa", data.payout_cpa / 100);
        props.setFieldValue("payout_cpl", data.payout_cpl / 100);
        props.setFieldValue("payout_scheme", data.payout_scheme);
        props.setFieldValue("is_visible", data.is_visible.toString());
        props.setFieldValue("ga_value", data.ga_value);
        props.setFieldValue("guaranteed_approve", data.guaranteed_approve);
      },
    }
  );

  if (isLoading) return null;

  return (
    <>
      <Form>
        <span style={{ fontSize: "22px" }}>Actual payout</span>

        {props.name && <div className={styles.userName}>{props.name}</div>}
        <section>
          <div className={styles.grid_col_2}>
            <div>
              <TextInputF
                {...props}
                name="product_sku"
                label="Product SKU"
                labelTextAlignLeft
                boldLabel
                disabled
              />
            </div>
            <div>
              <DictionaryF
                {...props}
                name="country_id"
                label={resources.labels.country}
                dictionaryValues={props.countryNameD}
                labelTextAlignLeft
                boldLabel
                disabled
              />
            </div>
            <div>
              <DictionaryF
                {...props}
                name="is_visible"
                label={resources.labels.visibility}
                dictionaryValues={visibilityD}
                labelTextAlignLeft
                boldLabel
              />
            </div>
            <div>
              <DictionaryF
                {...props}
                name="payout_scheme"
                label="Scheme"
                dictionaryValues={payoutSchemeD}
                labelTextAlignLeft
                boldLabel
              />
            </div>
            <div>
              <TextInputF
                {...props}
                name="payout_cpa"
                label="CPA [ $ ]"
                labelTextAlignLeft
                boldLabel
                isCurrency
                disabled={props.values.payout_scheme === "cpl"}
              />
            </div>
            <div>
              <TextInputF
                {...props}
                name="payout_cpl"
                label="CPL [ $ ]"
                labelTextAlignLeft
                boldLabel
                isCurrency
                disabled={props.values.payout_scheme === "cpa"}
              />
            </div>
          </div>
        </section>

        {data && (
          <div style={{ margin: "20px 0" }}>
            <span
              style={{
                display: "block",
                fontSize: "18px",
                marginBottom: "10px",
              }}
            >
              This change will occur at:
            </span>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <DatePicker
                  value={
                    props.values.started_at
                      ? moment(props.values.started_at).utc()
                      : undefined
                  }
                  style={{
                    marginRight: "10px",
                  }}
                  onChange={(date) => {
                    props.setFieldValue(
                      "started_at",
                      moment.utc(date).format()
                    );
                  }}
                  showTime
                  className={
                    props.errors.started_at && props.submitCount > 0
                      ? "invalid"
                      : ""
                  }
                  dropdownClassName="payout-datepicker"
                  disabled={props.values.apply_immediately}
                />
                {props.errors.started_at && props.submitCount > 0 && (
                  <div style={{ color: "red" }}>{props.errors.started_at}</div>
                )}
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "10px",
                }}
              >
                <Select
                  value={
                    timezones.find(
                      (timezone) => props.values.timezone === timezone.value
                    )?.name
                  }
                  onChange={(value: string) =>
                    props.setFieldValue("timezone", value)
                  }
                  className={
                    props.errors.timezone && props.submitCount > 0
                      ? "invalid"
                      : ""
                  }
                  placeholder="Select timezone"
                  disabled={props.values.apply_immediately}
                >
                  {timezones.map((timezone) => (
                    <Select.Option key={timezone.id} value={timezone.value}>
                      {timezone.name}
                    </Select.Option>
                  ))}
                </Select>

                {props.errors.timezone && props.submitCount > 0 && (
                  <div style={{ color: "red" }}>{props.errors.timezone}</div>
                )}
              </div>

              <div
                style={{
                  minWidth: "165px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Checkbox
                  checked={!!props.values.guaranteed_approve}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      props.setFieldValue("ga_value", null);
                    }

                    props.setFieldValue(
                      "guaranteed_approve",
                      Number(e.target.checked)
                    );
                  }}
                >
                  Guaranteed approve
                </Checkbox>
              </div>

              <span style={{ marginRight: "10px" }}>=</span>

              <div>
                <TextInputF
                  {...props}
                  type="number"
                  name="ga_value"
                  errors={props.errors}
                  disabled={!props.values.guaranteed_approve}
                />
              </div>

              <span style={{ marginLeft: "5px" }}>%</span>
            </div>
          </div>
        )}

        <Checkbox
          value={props.values.apply_immediately}
          checked={props.values.apply_immediately}
          onChange={() =>
            props.setFieldValue(
              "apply_immediately",
              !props.values.apply_immediately
            )
          }
        >
          Apply immediately
        </Checkbox>

        {props.values.apply_immediately && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 15px",
              marginTop: "15px",
              backgroundColor: "#f0f2f5",
            }}
          >
            <Icon
              type="warning"
              style={{ marginRight: "5px", fontSize: "18px", color: "#ffa500" }}
            />
            NOTE: the value will be applied within 5-7 minutes.
          </div>
        )}

        <Button className="saveButton" htmlType="submit" type="primary">
          {resources.buttons.save}
        </Button>
      </Form>

      {data.id && !data.is_handled && (
        <div style={{ marginTop: "15px" }}>
          <span
            style={{ fontSize: "18px", display: "block", marginBottom: "5px" }}
          >
            Future payout for this product
          </span>

          <table>
            <thead>
              <tr>
                <th style={{ paddingRight: "10px" }}>Date</th>
                <th style={{ padding: "0 10px" }}>Scheme</th>
                <th style={{ paddingLeft: "10px" }}>Visible</th>
                <th style={{ paddingLeft: "10px" }}>Payout</th>
                <th style={{ paddingLeft: "10px" }}>Guaranteed approve</th>
                <th style={{ paddingLeft: "10px" }}>GA value</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td style={{ paddingRight: "10px" }}>
                  {moment(data.started_at).utc().format("DD-MM-YYYY HH:mm")}
                </td>

                <td style={{ padding: "0 10px" }}>
                  {data.payout_scheme.toUpperCase()}
                </td>

                <td style={{ paddingLeft: "10px" }}>
                  {data.is_visible ? "Visible" : "Hidden"}
                </td>

                <td style={{ paddingLeft: "10px" }}>
                  {data.payout_scheme === "cpl"
                    ? data.payout_cpl / 100
                    : data.payout_cpa / 100}{" "}
                  USD
                </td>

                <td style={{ paddingLeft: "10px" }}>
                  {data.guaranteed_approve ? "ENABLED" : "DISABLED"}
                </td>

                <td style={{ paddingLeft: "10px" }}>{data.ga_value}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <PayoutsHistory
        userId={props.user_id as number}
        productId={props.product_id as number}
      />
    </>
  );
};

const PayoutAccessForm = withFormik<
  IPayoutAccessFormProps,
  IPayoutAccessFormProps
>({
  mapPropsToValues: (props: IPayoutAccessFormProps) => {
    return {
      product_sku: props.product_sku,
      name: props.name,
      country_id: props.country_id,
      product_id: props.product_id,
      payout_cpa: props.payout_cpa ? props.payout_cpa / 100 : undefined,
      payout_cpl: props.payout_cpl ? props.payout_cpl / 100 : undefined,
      is_visible: props.is_visible?.toString(),
      payout_scheme: props.payout_scheme,
      started_at: props.started_at,
      timezone: props.timezone,
      apply_immediately: true,
      guaranteed_approve: props.guaranteed_approve,
      ga_value: props.ga_value,
    };
  },

  validate: (values: IPayoutAccessFormProps) => {
    const errors: FormikErrors<IPayoutAccessFormProps> = {};

    if (!values.timezone && !values.apply_immediately) {
      errors.timezone = "Required field";
    }

    if (!values.started_at && !values.apply_immediately) {
      errors.started_at = "Required field";
    }

    if (values.guaranteed_approve && values.ga_value < 1) {
      errors.ga_value = "GA value shoud be more than 0";
    }

    if (values.guaranteed_approve && values.ga_value > 100) {
      errors.ga_value = "GA value shoud be less or equal than 100";
    }

    return errors;
  },

  handleSubmit: (
    values: IPayoutAccessFormProps,
    bag: FormikBag<IPayoutAccessFormProps & IHideModal, IPayoutAccessFormProps>
  ) => {
    const object = [
      {
        id: values.id,
        product_id: values.product_id ? values.product_id : null,
        payout_cpa: values.payout_cpa ? Math.round(values.payout_cpa * 100) : 0,
        payout_cpl: values.payout_cpl ? Math.round(values.payout_cpl * 100) : 0,
        payout_scheme: values.payout_scheme,
        is_visible: Number(values.is_visible),
        country_id: values.country_id,
        started_at: values.apply_immediately
          ? moment().utcOffset(0, true).format()
          : moment(values.started_at).utc().format(),
        timezone: values.apply_immediately
          ? moment().format("Z")
          : values.timezone,
        guaranteed_approve: values.guaranteed_approve,
        ga_value: values.ga_value ? Number(values.ga_value) : null,
      },
    ];

    updatePayouts(object, bag.props.user_id!).then((response) => {
      if (response) {
        openSuccessNotification(resources.statement.updated);
        bag.props.hideModal!();
      }
    });
  },
})(PayoutAccessFormInner);

export default PayoutAccessForm;
