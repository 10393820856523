import AwesomeDebouncePromise from "awesome-debounce-promise";
import DictionaryF from "../../../components/templateForm/form/dropDown/DictionaryF";
import moment from "moment";
import ProtectedComponent from "../../../components/authorizations/protectedComponents/ProtectedComponent";
import React from "react";
import SeperateRangePicker from "../../../components/templateForm/form/seperateRangePicker/SeperateRangePicker";
import TextInputF from "../../../components/templateForm/form/textInput/TextInputF";
import { Button, Divider } from "antd";
import { DateButtonE } from "../../../common/enums/DateButtonE";
import { displayErrorMessage } from "../../../helpers/MessageHelper";
import { Form, FormikBag, FormikProps, withFormik } from "formik";
import { getPartnersByEmail } from "../../../services/m29_users_management/GeneralUserService";
import { IDictionaryValue } from "../../../components/templateForm/types/dictionaries/IDictionaryValue";
import { IHideModal } from "../../../common/interfaces/IHideModal";
import { ILead } from "../interfaces/ILead";
import { ILeadFiltersActions } from "../interfaces/ILeadFiltersActions";
import { ILeadsFormProps } from "../interfaces/ILeadsFormProps";
import { resources } from "../../../common/Resources";
import { timezones } from "../../../common/dictionaries/Timezones";
import { UserPermissionType } from "../../../models/IAppUserInfoModel";
import { IAppState } from "../../../store";
import { connect } from "react-redux";

class TemplateLeadsFiltersForm extends React.Component<
  ILead &
    ILeadsFormProps &
    ILeadFiltersActions &
    IHideModal &
    FormikProps<ILead>
> {
  state = {
    // Button filtrs
    today: false,
    yesterday: false,
    thisMonth: false,
    lastMonth: false,
    last30Days: false,
    ccClassifiersD: [] as IDictionaryValue[],
  };

  componentDidMount = () => {
    this.generateUnicueCCClasifiersD(this.props.ccClassifiersD);
  };

  UNSAFE_componentWillReceiveProps = (props: ILeadsFormProps) => {
    this.generateUnicueCCClasifiersD(props.ccClassifiersD);
  };

  public render() {
    const {
      statusesD,
      countriesNameD,
      productsD,
      payoutSchemaD,
      remarketingCampaignsD,
      companiesD,
    } = this.props;
    const {
      today,
      yesterday,
      thisMonth,
      last30Days,
      lastMonth,
      ccClassifiersD,
    } = this.state;

    let filteredProductsD = productsD;

    if (this.props.values.country_id) {
      filteredProductsD = filteredProductsD.filter(
        (x) => x.country_id === this.props.values.country_id
      );
    }

    return (
      <div className={"customAnt--leadsFilters"}>
        <Form>
          <div className="grid_col_3">
            <div className="rangePickerWidth">
              <SeperateRangePicker
                {...this.props}
                label={resources.labels.dateRange}
                name="date"
                labelTextAlignLeft
                boldLabel
                maxRangeInDays={31}
              />
            </div>
            <div className="tomezoneStyle">
              <DictionaryF
                {...this.props}
                name="timezone"
                label={resources.labels.timezone}
                dictionaryValues={timezones}
                labelTextAlignLeft
                boldLabel
              />
            </div>
          </div>
          <div className="grid_col_5">
            <div>
              <Button
                className="select_button_style"
                disabled={today}
                name="today"
                value={DateButtonE.today}
                onClick={this.onClickButton}
              >
                {resources.buttons.today}
              </Button>
            </div>
            <div>
              <Button
                className="select_button_style"
                disabled={yesterday}
                name="yesterday"
                value={DateButtonE.yesterday}
                onClick={this.onClickButton}
              >
                {resources.buttons.yesterday}
              </Button>
            </div>
            <div>
              <Button
                className="select_button_style"
                disabled={thisMonth}
                name="thisMonth"
                value={DateButtonE.thisMonth}
                onClick={this.onClickButton}
              >
                {resources.buttons.thisMonth}
              </Button>
            </div>
            <div>
              <Button
                className="select_button_style"
                disabled={last30Days}
                name="last30Days"
                value={DateButtonE.last30Days}
                onClick={this.onClickButton}
              >
                {resources.buttons.last30Days}
              </Button>
            </div>
            <div>
              <Button
                className="select_button_style"
                disabled={lastMonth}
                name="lastMonth"
                value={DateButtonE.lastMonth}
                onClick={this.onClickButton}
              >
                {resources.buttons.lastMonth}
              </Button>
            </div>
          </div>
          <div className="grid_col_4">
            <div
              className={`${
                this.props.values.id_lead_status !== undefined &&
                "activeFilterStyle"
              }`}
            >
              <DictionaryF
                {...this.props}
                name="id_lead_status"
                label={resources.labels.status}
                placeholder={resources.placeholders.choose_affbay_status}
                dictionaryValues={statusesD}
                labelTextAlignLeft
                allowClear={true}
                boldLabel
              />
            </div>

            {(this.props as any).userRole !==
              UserPermissionType.CountryManagerRole && (
              <div
                className={`${
                  this.props.values.country_id !== undefined &&
                  "activeFilterStyle"
                }`}
              >
                <DictionaryF
                  {...this.props}
                  name="country_id"
                  label={resources.labels.country}
                  placeholder={resources.placeholders.choose_country}
                  customOnChange={this.changeCountryId}
                  dictionaryValues={countriesNameD}
                  labelTextAlignLeft
                  allowClear
                  boldLabel
                />
              </div>
            )}

            <div
              className={`${
                this.props.values.id_product !== undefined &&
                "activeFilterStyle"
              }`}
            >
              <DictionaryF
                {...this.props}
                name="id_product"
                label={resources.labels.product}
                placeholder={resources.placeholders.choose_product}
                dictionaryValues={filteredProductsD}
                labelTextAlignLeft
                allowClear
                boldLabel
              />
            </div>
            <ProtectedComponent
              permission={UserPermissionType.SeeLeadsSelectPartnerButton}
            >
              <div
                className={`${
                  this.props.values.id_partner !== undefined &&
                  "activeFilterStyle"
                }`}
              >
                <DictionaryF
                  {...this.props}
                  name="id_partner"
                  label={resources.labels.partner}
                  placeholder={resources.placeholders.enter_min_4_letters}
                  dictionaryValuesProvider={(searchPhrase) =>
                    this.getPartnersDDebounced(searchPhrase)
                  }
                  dictionaryValues={this.props.partnersD}
                  labelTextAlignLeft
                  allowClear
                  boldLabel
                />
              </div>
            </ProtectedComponent>
          </div>
          <div className="grid_col_4">
            <div
              className={`${
                this.props.values.payout_scheme !== undefined &&
                "activeFilterStyle"
              }`}
            >
              <DictionaryF
                {...this.props}
                name="payout_scheme"
                label={resources.labels.payoutScheme}
                placeholder={resources.placeholders.choose_scheme}
                dictionaryValues={payoutSchemaD}
                labelTextAlignLeft
                allowClear
                boldLabel
              />
            </div>
            <div
              className={`${
                this.props.values.cc_classifiers_name !== undefined &&
                "activeFilterStyle"
              }`}
            >
              <DictionaryF
                {...this.props}
                name="cc_classifiers_name"
                label={resources.labels.classifierName}
                placeholder={resources.placeholders.choose_classifier}
                dictionaryValues={ccClassifiersD}
                labelTextAlignLeft
                allowClear
                boldLabel
              />
            </div>
            <div
              className={`${
                this.props.values.id !== undefined &&
                this.props.values.id.length &&
                "activeFilterStyle"
              }`}
            >
              <TextInputF
                {...this.props}
                name="id"
                label={resources.labels.affbay_lead_id}
                labelTextAlignLeft
                allowClear
                boldLabel
                isNumeric
              />
            </div>
            <div
              className={`${
                this.props.values.api_click_id !== undefined &&
                "activeFilterStyle"
              }`}
            >
              <TextInputF
                {...this.props}
                name="api_click_id"
                label={resources.labels.click_id}
                labelTextAlignLeft
                allowClear
                boldLabel
              />
            </div>
          </div>
          <div className="grid_col_4">
            <div
              className={`${
                this.props.values.api_pub_id !== undefined &&
                "activeFilterStyle"
              }`}
            >
              <TextInputF
                {...this.props}
                name="api_pub_id"
                label={resources.labels.pub_id}
                labelTextAlignLeft
                allowClear
                boldLabel
              />
            </div>
            <div
              className={`${
                this.props.values.client_phone1 !== undefined &&
                "activeFilterStyle"
              }`}
            >
              <TextInputF
                {...this.props}
                name="client_phone1"
                label={resources.labels.phone}
                labelTextAlignLeft
                allowClear
                boldLabel
              />
            </div>
            <ProtectedComponent
              permission={
                UserPermissionType.SeeLeadsSelectRemarketingCampaignButton
              }
            >
              <div
                className={`${
                  this.props.values.traffic_source !== undefined &&
                  "activeFilterStyle"
                }`}
              >
                <DictionaryF
                  {...this.props}
                  name="traffic_source"
                  label={resources.labels.remarketingCampaign}
                  placeholder="Default [ null, 1, 5 ]"
                  // placeholder={resources.placeholders.choose_remarketing_campaign}
                  dictionaryValues={remarketingCampaignsD}
                  labelTextAlignLeft
                  allowClear
                  boldLabel
                />
              </div>
            </ProtectedComponent>
            <ProtectedComponent
              permission={UserPermissionType.SeeLeadsSelectCompanyButton}
            >
              <div
                className={`${
                  this.props.values.traffic_source !== undefined &&
                  "activeFilterStyle"
                }`}
              >
                <DictionaryF
                  {...this.props}
                  name="id_company"
                  label={resources.labels.company}
                  placeholder={resources.placeholders.choose_company}
                  customOnChange={this.changeCountryId}
                  dictionaryValues={companiesD}
                  labelTextAlignLeft
                  allowClear
                  boldLabel
                />
              </div>
            </ProtectedComponent>
          </div>
          <div className="grid_col_4">
            <ProtectedComponent
              permission={UserPermissionType.SeeQualifiedStatus}
            >
              <div
                className={`${
                  this.props.values.is_qualified !== undefined &&
                  "activeFilterStyle"
                }`}
              >
                <DictionaryF
                  {...this.props}
                  name="is_qualified"
                  label={resources.columnTitle.is_qualified}
                  dictionaryValues={this.props.booleanSelect}
                  labelTextAlignLeft
                  allowClear
                  boldLabel
                />
              </div>
            </ProtectedComponent>
            <ProtectedComponent
              permission={UserPermissionType.SeeContactedStatus}
            >
              <div
                className={`${
                  this.props.values.is_contacted !== undefined &&
                  "activeFilterStyle"
                }`}
              >
                <DictionaryF
                  {...this.props}
                  name="is_contacted"
                  label={resources.columnTitle.is_contacted}
                  dictionaryValues={this.props.booleanSelect}
                  labelTextAlignLeft
                  allowClear
                  boldLabel
                />
              </div>
            </ProtectedComponent>
          </div>
          <Divider />
          <div className="grid_col_4">
            <div />
            <div />
            <div className="item">
              <Button className="submit_button_style" htmlType="submit">
                {resources.buttons.submit}
              </Button>
            </div>
            <div className="item">
              <Button
                className="reset_button_style"
                onClick={this.resetFilters}
              >
                {resources.buttons.resetFilters}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  getPartnersD = async (searchPhrase: string): Promise<IDictionaryValue[]> => {
    if (searchPhrase.length <= 3) {
      return [];
    }
    const res = await getPartnersByEmail(searchPhrase);
    return res
      ? res.sort((a: any, b: any) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
      : [];
  };

  getPartnersDDebounced = AwesomeDebouncePromise(
    (searchPhrase: string) => this.getPartnersD(searchPhrase),
    1500
  );

  onClickButton = (event: any) => {
    this.resetFiltersFlag();

    switch (Number(event.target.value)) {
    case DateButtonE.today:
      this.props.setFieldValue("date", [
        moment(new Date()).startOf("day"),
        moment(new Date()).endOf("day"),
      ]);

      this.setState({ [event.target.name]: true });

      break;
    case DateButtonE.yesterday:
      this.props.setFieldValue("date", [
        moment(new Date()).subtract(1, "days").startOf("day"),
        moment(new Date()).subtract(1, "days").endOf("day"),
      ]);

      this.setState({ [event.target.name]: true });

      break;
    case DateButtonE.thisMonth:
      this.props.setFieldValue("date", [
        moment(new Date()).startOf("month"),
        moment(new Date()).endOf("day"),
      ]);

      this.setState({ [event.target.name]: true });

      break;
    case DateButtonE.lastMonth:
      this.props.setFieldValue("date", [
        moment(new Date()).subtract(1, "month").startOf("month"),
        moment(new Date()).subtract(1, "month").endOf("month"),
      ]);

      this.setState({ [event.target.name]: true });

      break;
    case DateButtonE.last30Days:
      this.props.setFieldValue("date", [
        moment(new Date()).subtract(29, "days").startOf("day"),
        moment(new Date()).endOf("day"),
      ]);

      this.setState({ [event.target.name]: true });

      break;
    default:
      displayErrorMessage(resources.statement.choose_out_of_scope);
      return null;
    }
  };

  resetFilters = () => {
    this.props.setFieldValue("date", [
      moment(new Date()).subtract(1, "days").startOf("day"),
      moment(new Date()).endOf("day"),
    ]);
    this.props.setFieldValue("id", undefined);
    this.props.setFieldValue("id_lead_status", undefined);
    this.props.setFieldValue("country_id", undefined);
    this.props.setFieldValue("id_company", undefined);
    this.props.setFieldValue("id_product", undefined);
    this.props.setFieldValue("id_partner", undefined);
    this.props.setFieldValue("payout_scheme", undefined);
    this.props.setFieldValue("api_click_id", undefined);
    this.props.setFieldValue("api_pub_id", undefined);
    this.props.setFieldValue("client_phone1", undefined);
    this.props.setFieldValue("cc_classifiers_name", undefined);
    this.props.setFieldValue("timezone", this.props.timezone);
    this.props.setFieldValue("traffic_source", undefined);
    this.props.setFieldValue("is_qualified", undefined);
    this.props.setFieldValue("is_contacted", undefined);

    this.resetFiltersFlag();
  };

  resetFiltersFlag = () => {
    this.setState({
      today: false,
      yesterday: false,
      thisMonth: false,
      lastMonth: false,
      last30Days: false,
    });
  };

  changeCountryId = () => {
    this.props.setFieldValue("id_product", undefined);
  };

  onDateChange = (dateTime: any) => {
    this.resetFiltersFlag();

    this.props.setFieldValue("date", [
      moment(new Date(dateTime[0])).startOf("day"),
      moment(new Date(dateTime[1])).endOf("day"),
    ]);
  };

  handleChange = (active_key: string | string[]) => {
    this.setState({ active_key });
  };

  generateUnicueCCClasifiersD = (list: IDictionaryValue[]) => {
    let ccClassifiersD: IDictionaryValue[] = [];

    list.map((item: IDictionaryValue) => {
      if (ccClassifiersD.findIndex((x) => x.name === item.name) === -1)
        ccClassifiersD.push({ id: item.name, name: item.name });

      return null;
    });

    this.setState({ ccClassifiersD });
  };
}

const LeadsFiltersForm = withFormik<
  ILead & ILeadsFormProps & ILeadFiltersActions,
  ILead
>({
  mapPropsToValues: (props: ILead) => {
    return {
      date: props.date,
      id: props.id,
      id_lead_status: props.id_lead_status,
      country_id: props.country_id,
      id_company: props.id_company,
      id_product: props.id_product,
      id_partner: props.id_partner,
      payout_scheme: props.payout_scheme,
      api_click_id: props.api_click_id,
      api_pub_id: props.api_pub_id,
      client_phone1: props.client_phone1,
      cc_classifiers_name: props.cc_classifiers_name,
      timezone: props.timezone,
      traffic_source: props.traffic_source,
      is_contacted: props.is_contacted,
      is_qualified: props.is_qualified,
    };
  },

  handleSubmit: (
    values: ILead,
    bag: FormikBag<ILeadFiltersActions & IHideModal, {}>
  ) => {
    const object: any = {
      dateFrom: values.date[0],
      dateTo: values.date[1],
      id: values.id,
      id_lead_status: values.id_lead_status,
      country_id: values.country_id,
      id_company: values.id_company,
      id_product: values.id_product,
      id_partner: values.id_partner,
      payout_scheme: values.payout_scheme,
      api_click_id: values.api_click_id,
      api_pub_id: values.api_pub_id,
      client_phone1: values.client_phone1,
      cc_classifiers_name: values.cc_classifiers_name,
      traffic_source: values.traffic_source,
      timezone: values.timezone,
      is_contacted: values.is_contacted,
      is_qualified: values.is_qualified,
    };

    bag.props.setFilters(object);
    bag.props.getLeads();
    bag.props.hideModal!();
  },
  enableReinitialize: true,
})(TemplateLeadsFiltersForm);

export const mapStateToProps = (
  state: IAppState
): { userRole: number | undefined } => ({
  userRole: state.commonState.appUserInfo.id_users_role,
});

export default connect(mapStateToProps)(LeadsFiltersForm);
